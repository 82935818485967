import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
// import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../api'
import './CSS.css';

const GraphPage =() =>{
    const [data, setData] = useState([]);

    useEffect(() => {
        api.get('KitCatzII')
    //   axios.get('http://m2c1.freeboxos.fr/api/ktct/v2/KitCatzII')
        .then(response => {
          setData(response.data.result);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }, []);

    // const singleData = data.slice(0, 1); // La 16e donnée
    const group1 = data.slice(0, 5); // Les 5 premiéres données
    const group2 = data.slice(5, 10); // Les 5 données suivantes
    const group3 = data.slice(10, 15); // Les 5 données suivantes


    return (
        <div>
            <Container className='container1'>
                <Row>
                    <Col className='container'>
                        {/* <Container >
                            <Row >
                                {singleData.map(item => (
                                <Col>
                                        
                                    <h6>{new Date(item?.DateTime*1000).toLocaleDateString()} {new Date(item?.DateTime*1000).toLocaleTimeString()}</h6>
                                    <h6>Poids de Patsy : {Math.round(item.WgtCat)/1000} Kg</h6>
                                    <h6>Poids de l'assiétte {Math.round(item.LgtFoo)} Grammes</h6>
                                    <h6>pendent {item.UtiDur} secondes</h6>
                                        
                                </Col>
                                ))}
                            </Row>
                        </Container> */}
                        <Container >
                            <Row >
                            {group1.map(item => (
                                <Col>
                                        
                                    <h6>{new Date(item?.DateTime*1000).toLocaleDateString()} {new Date(item?.DateTime*1000).toLocaleTimeString()}</h6>
                                    <h6>Poids de Patsy : {Math.round(item.WgtCat)/1000} Kg</h6>
                                    <h6>Poids de l'assiétte {Math.round(item.LgtFoo)} Grammes</h6>
                                    <h6>pendent {item.UtiDur} secondes</h6>
                                        
                                </Col>
                                ))}
                            </Row>
                        </Container>
                        <Container>
                            <Row >
                                {group2.map(item => (
                                <Col>
                                            
                                    <h6>{new Date(item?.DateTime*1000).toLocaleDateString()} {new Date(item?.DateTime*1000).toLocaleTimeString()}</h6>
                                    <h6>Poids de Patsy : {Math.round(item.WgtCat)/1000} Kg</h6>
                                    <h6>Poids de l'assiétte {Math.round(item.LgtFoo)} Grammes</h6>
                                    <h6>pendent {item.UtiDur} secondes</h6>
                                            
                                </Col>
                                ))}
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                {group3.map(item => (
                                <Col>
                                        
                                    <h6>{new Date(item?.DateTime*1000).toLocaleDateString()} {new Date(item?.DateTime*1000).toLocaleTimeString()}</h6>
                                    <h6>Poids de Patsy : {Math.round(item.WgtCat)/1000} Kg</h6>
                                    <h6>Poids de l'assiétte {Math.round(item.LgtFoo)} Grammes</h6>
                                    <h6>pendent {item.UtiDur} secondes</h6>
                                    
                                </Col>
                                ))}
                            </Row>
                        </Container>
                    </Col>
                    <Col>
                        <h3> DataViz Part</h3>
                    </Col>
                </Row>
            </Container>
        </div>
      );
    }
 
export default GraphPage;