import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import './CSS.css';

const PrésPage =() =>{

    return (

        <div>
            {/* <Header/>
            <NavBar/> */}
            <h2 className='color'>
                présentation du projet
            </h2>
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSaTioZGnRBbau1RKoI_zFWz4E8J4D_AHT24o_pJF0CKLXxhFesK0v5Btact_7hLuQTprMi9Cvu_mef/embed?start=false&loop=true&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            {/* <Link to="/"class="button big gray" >Go back to home</Link> */}

        </div>

    )
}
export default PrésPage;