import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const api = axios.create({
  baseURL: 'http://m2c1.freeboxos.fr/api/ktct/v2/', // Supprime l'apostrophe et l'espace
  withCredentials: true,
});

export default api;