import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className="Footer">
      @ 2025 Kitchen Cat Project
    </div>
  )
}

export default Footer