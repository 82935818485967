import React from 'react';
// import React, { components, ReactDOM } from 'react';
// import React, { components} from 'react'
import './App.css';
import Navbar from './Components/Navbar/index.js';
import Footer from './Components/Footer/Footer.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import AdminPage from './Pages/Admin';
// import api from './api';
import Home from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import PresPage from './Pages/OnePage.js';
import GraphPage from './Pages/TwoPage.js';

function App() {
  return (
    
    <div className='App'> 
      <Router>
        <Navbar/>

        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path='/Prés' element={<PresPage/>} />
          <Route path='/Graph' element={<GraphPage/>} />
          <Route path='/About' element={<AboutPage/>} />
          {/* <Route path='/Gra' element={<GraphPage/>} /> */}
          {/* <Route path='/Login' element={<LoginPage/>} /> */}
          {/* <Route path='/LineChart' element={<LineChart/>} /> */}
          {/* <Route path='/autohome' element={<OnePage/>} /> */}
          {/* <Route path='*' element={<NoPage/>} /> */}
        </Routes>
      </Router>
      <Footer/>
    </div>

  );
}
  
export default App;

