
import React from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';

import Icon from './m2c1.png'

import './CSS.css'

const Navbar = () => {
  return (
    <>
      <Nav>
        <Bars />
        <NavMenu>
          <NavLink to='/' activeStyle>
            <img className='ico' src={Icon} alt='m2c1' />
          </NavLink>
          <NavLink to='/Prés' activeStyle>
            <h6>Présentation</h6>
          </NavLink>
          <NavLink to='/Graph' activeStyle>
            <h6>Dataviz</h6>
          </NavLink>
          <NavLink to='/About' activeStyle>
            <h6>About me</h6>
          </NavLink>
          <NavLink to='*' activeStyle>
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};
  
export default Navbar;