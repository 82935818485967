import React from 'react'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer/Footer'

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';

import './CSS.css'

import dyslexia from '../Medias/dyslexie.jpg'
import diplome from '../Medias/diplome.png'
import Docto from '../Medias/Doctolib.png'
import imdb from '../Medias/imdb.jpg'
import keolis from '../Medias/keolis.jpg'
import sql from '../Medias/sql.jpg'
import topos from '../Medias/topos.jpg'
import wild from '../Medias/maxresdefault.jpg'
import ligair from '../Medias/logo_ligair.jpg'





const About_ME = () => {

    return (

        <div className='place'>

            <h4>
         
            </h4>
            {/* <Link to="/"class="button big gray" >Go back to home</Link> */}

            <Container>
                <Row>
                    <Col>
                        {/* <h3 className='color'>Vous vous demandez comment je suis arriver l� ?</h3> */}
                        <div>
                            <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                <h3>Tout les chemins qui m'ont conduit ici</h3></Accordion.Header>
                                    <Accordion.Body>
                                        <Container>
                                            <div>
                                                <Row>
                                                
                                                    <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='ico' src={dyslexia} alt='dyslexie' /> dyslexie </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                Si la vue de coquilles dans un texte et les terminaisons raté vous insupportes, je puis vous assurez que je fait du mieux que je le puis, mais quittez là, la suite pourrait s'avérer quelque peut traumatisant .
                                                                Toute fois je vous laisse ce lien vers une play liste Youtube <a href="https://www.youtube.com/watch?v=VmaxajlqvRU&list=PLqhlzPL6Evk3EbJ-cmU-vWrcFkFYBK8ih" target="_blank">Je parle France</a>
                                                                bonne journée </h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='ico' src={diplome} alt='dyslexie' />  </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                Mon seul et unique diplome est un certificat de conducteur de chien, obtenue lors de mon service militaire dans l'armée de l'aire dans le corps des fusillés commandos -section cynophile -
                                                                le pourquoi se trouve a gauche de ceci .</h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Container>
                                        <Container>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader>1994 - 2000 </AccordionHeader>
                                                            <Accordion.Body><h4>
                                                                            Moteur Cableur en courant faible
                                                                             </h4>
                                                                             <h6>
                                                                             Je me suis rapidement spécialisé dans le réseau informatique ( Rj45 ) 
                                                                             puis Fibre optique avant de me sédentariser en prenant en charge la gestions 
                                                                             du magasin et la préparation du matèriel pour les équipes sur le terrain .
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="2">
                                                            <AccordionHeader>2004 - 2009 </AccordionHeader>
                                                            <Accordion.Body><h4></h4>
                                                            <h6>
                                                                             Intérim dans le secteur du cablage courant faible, mission longue pour AEI et Péage Parck.
                                                                             Mission d'un mois pour British Telecom au sein de EADS ( qui reviendras en 2010; 2011 et 2012 )
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader>2011 - 2019 </AccordionHeader>
                                                            <Accordion.Body><h4>
                                                                            Petit travaux et formations
                                                                             </h4>
                                                                             <h6>
                                                                             Intérim et formation d'agent de production
                                                                             ( une discutions avec une formatrice m'a poussé vers une 
                                                                             formation dans le domaine du développement informatique )
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                            <Accordion.Item eventKey="1">
                                                                <AccordionHeader>2000 - 2004</AccordionHeader>
                                                                <Accordion.Body><h4>
                                                                                Gestion de stock  Préparation de commande 
                                                                                </h4>
                                                                                <h6>
                                                                                Gestion du stock de 1500 pièces destiné à la fabrication d'enceintes a haute contraintes,
                                                                                préparation de commandes clients de pièces et prototypes ainsi que la prise en charge de 
                                                                                cible almuminium pour évaporation sous vide;
                                                                                </h6>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                                <AccordionHeader>2009 - 2011</AccordionHeader>
                                                                <Accordion.Body><h4>
                                                                                Gatinais Emploi 
                                                                                </h4>
                                                                                <h6>
                                                                                Aide au jardinage et bricolage dans le cadre de l'association .
                                                                                </h6>
                                                                </Accordion.Body>
                                                                </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    </Row>
                                            </div>
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header> Wild Code School</Accordion.Header>
                                    <Accordion.Body>
                                    <Container>
                                            <div>
                                                <Row>
                                                
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader>< img className='ico3' src={sql} alt='My SQL' /> Toy's and Models </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Création d'un dashboard à partir d'une base de données SQL
                                                                            Création d'un outil d'aide à la décision 
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader>< img className='ico' src={keolis} alt='Keolis Orl�ans' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                            Automatiser le traitement et regroupement de données. Traitement de trois
                                                                            bases de données, et visualisation par power BI
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader>< img className='ico4' src={wild} alt='Datathon-1' /> Datathon </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche et exploitation de bases de données relatives aux
                                                                            élections municipales dont l'objectif est de déterminer le ratio
                                                                            homme/femme parmi les élus.

                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                           
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader>< img className='ico' src={imdb} alt='IMDB database' /> IMDB recommandations </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Analyse et exploitation d'un dataset de recommandations de films
                                                                            Création d'un modéle de machine learning
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader>< img className='ico' src={ligair} alt='lig_air' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            création d'un outils web de calcule de consommation / pollution, rendu par
                                                                            graphique selon data-set client 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader>< img className='ico' src={Docto} alt='Datathon-2' /> Doctolib & dataiku </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche et exploitation de bases de données publiques et relatives à la santé. 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    </Row>
                                            </div>                                            
                                        </Container>
                                        <Container>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="6">
                                                            <AccordionHeader>< img className='ico' src={topos} alt='Topos urbanisme' /> stage en entreprise </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche de thémes et prescriptions légale pare mots clés 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    {/* <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='ico' src={ligair} alt='lig_air' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche de th�mes et prescriptions l�gale pare mots cl�s 
                                                                            </h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col> */}
                                                </Row>
                                                </div>                                            
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header> En Suite </Accordion.Header>
                                    <Accordion.Body>
                                    <Container>
                                            <div>
                                                <Row>
                                                
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader> 2021 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                  j'ai continuer a travailler sur Django et React,  
                                                                                  "_Will be Back_"
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader> Projet Maison D'automne </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                création d'une station de controle
                                                                                environementale intèrieur et extérieur.                                                                                
                                                                            </h6>
                                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader> Analyse </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Analyse des data
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                           
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader> 2022 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                je commence a travailler sur raspberry pi 4, ESP32
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader> découverte  </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                j'éxplore le système Raspberry PI
                                                                                , Arduino et ESP32
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader> Developpement </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                developpements de server Django et React
                                                                                pour l'entrainement .
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader> 2023 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                le visite chez ma vétérinaire
                                                                                me décide à lancer le projet KitCat
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader> 2023 Projet KitCat </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                prise en main des systémes PICO
                                                                                et développement MicroPython
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader> création de site </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                développement de ce site et sur utilisation
                                                                                de l'accordeon dans la page 'CV'
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader> 2024 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                déménagement et installation dans ma maison 
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader> Projet projet KitCat </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                mise en place du matériel et début de l'ajout 
                                                                                du distributeur de croquettes                                                                                
                                                                            </h6>
                                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader> dépanage </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Mon imprimante 3D à mal vécue le déplacement
                                                                                je doit changer la carte mère
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader> 2025 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                une commande malheureuse a entiérement bloquer
                                                                                mon raspberry, je doit réinstaller et tout recomencer      
                                                                            </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader> le site est en ligne </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                si vous étes là et que je suis ici
                                                                                alors tout est prèt                                                                                
                                                                            </h6>
                                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader> en suite </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                la suite
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            {/* <AccordionHeader> 2024 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                  j'ai continuer a travailler sur Django et React,  
                                                                                  "_Will be Back_"
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader> Projet Maison D'automne </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                création d'une station de controle
                                                                                environementale intèrieur et extérieur.                                                                                
                                                                            </h6>
                                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader> Analyse </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Analyse des data
                                                                            </h6>
                                                            </Accordion.Body> */}
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                

                                                </Row>
                                            </div>                                            
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>

    )



}
export default About_ME;