import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import './CSS.css';

const HomePage =() =>{

    return (

        <div>
            {/* <Header/>
            <NavBar/> */}
            <h4 className='color'>
                nothing around nowhere
            </h4>
            {/* <Link to="/"class="button big gray" >Go back to home</Link> */}

        </div>

    )
}
export default HomePage;